<template>
<v-app>

    <div class="MainSite">
        <Login v-if="!$SALE_ID" />
        <Menu v-if="$SALE_ID" />

        <div class="mt-5">
            <router-view :key="$KEY" v-if="$SALE_ID"></router-view>
        </div>
    </div>

</v-app>
</template>

<script>
import Login from './Login.vue'
import Menu from './Menu.vue'
export default {
    name: 'App',

    components: {

        Login,
        Menu
    },

    data: () => ({

    }),

    computed: {

    },

    watch: {

    },
    methods: {

    },
    created() {

    },
};
</script>
