import Vue from "vue";
 
import moment from "moment";

require("moment/locale/th");
 var momentDurationFormatSetup = require("moment-duration-format");
 console.log(moment.duration(123, "minutes").format())

 let globalData = new Vue({
   data: {
     $DEALER_JOIN: null, 
   },
 });



Vue.mixin({
  computed: { 
  
 
  },

  methods: {
   

  },
});
