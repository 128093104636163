import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


const storeUser = new Vuex.Store({
  strict: true,
  state: {
    user: {
      sale_id: null,
      firstname: null,
      lastname: null,
      token: null,
      picture: null,
      username: null,
      time: null,
    },
    sale_id: null,
  },
  mutations: {
    set(state, user_data) {
      state.user = user_data;
    },
    setId(state, sale_id) {
      state.sale_id = sale_id;
    },
    setLogout(state, sale_id) {
      state.user.token = null;
    },
  },
  actions: {},
  getters: {
    get: (state) => {
      return state.sale;
    },
    getId: (state) => {
      return state.sale_id;
    },
  },
});



export default storeUser  