import Vue from 'vue'
import VueRouter from 'vue-router'
 

Vue.use(VueRouter)



const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../HomeMain.vue"),
  },

  {
    path: "/account/profile",
    name: "accountProfile",
    component: () => import("../views/Account/Profile.vue"),
  },

  {
    path: "/account/change_password",
    name: "accountChangePassword",
    component: () => import("../views/Account/ChangePassword.vue"),
  },

  {
    path: "/property/used",
    name: "propertyUsed",
    component: () => import("../views/Property/Used.vue"),
  },

  {
    path: "/property/new",
    name: "propertyNew",
    component: () => import("../views/Property/New.vue"),
  },

  {
    path: "/register",
    name: "Register",
    component: () => import("../views/Register/Register.vue"),
  },

  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact/Contact.vue"),
  },
];

    Vue.prototype.$KEY = null;

const router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  mode: "history",
  base: process.env.VUE_APP_DIR,
  routes,
});

router.beforeEach((to, from, next) => {
  Vue.prototype.$KEY = Math.random();

  if (to.matched.some((record) => record.meta.reuse === true)) {
    Vue.prototype.$KEY = null;
  } else {
    /*  if(from.name != 'Account') {
       Vue.prototype.$KEY = Math.random();
      } */
  }
  next();
});

export default router
