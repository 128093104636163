<template>
<div>

    <div class="mx-auto mt-5 text-center  animate__animated animate__zoomIn" style="max-width:500px;margin:auto; padding-top:50px;">

        <div class="text-overline mb-4">
  <img src="./assets/img/bkk.svg" class="logo">
        </div>

        <div class="row ">
            <div class="col-12">
                <v-card class="mx-auto text-center" max-width="400" style="background-color:transparent;border:none;box-shadow: none;" :class="boxLoginClass">
                    <v-list-item three-line>
                        <v-list-item-content>

                          
                            <div v-html="loginMsg" class="mb-2"></div>

                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-text-field v-model="username" :rules="nameRules" label="Username หรือ Email" required outlined></v-text-field>

                                <v-text-field type="password" v-model="password" :rules="passwordRules" label="รหัสผ่าน" required outlined></v-text-field>

                                <div class="text-center">
                                    <div class="row">
                                        <div class="col"> <a  href="#">ลืมรหัสผ่าน</a></div>
                                        <div class="col text-right">
                                            <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">
                                                เข้าระบบ
                                            </v-btn>
                                        </div>
                                    </div>

                                </div>

                            </v-form>

                        </v-list-item-content>

                    </v-list-item>

                </v-card>
            </div>
        </div>

    </div>

</div>
</template>

<script>
export default {
    name: 'Member',
    components: {

    },

    data() {
        return {
            valid: true,

            nameRules: [
                v => !!v || 'โปรดระบุ Username หรือ Email ด้วยค่ะ'
            ],

            passwordRules: [
                v => !!v || 'โปรดระบุ รหัสผ่านด้วยค่ะ'
            ],
            select: null,

            boxLoginClass: null,
            logingIn: false,
            loginMsg: null,
            maximizedToggle: true,
            username: '',
            password: '',
            loginBox: false,
            left: false,
            right: false
        }
    },
    computed: {
        BLOCK() {

            return this.$STORE_GLOBAL.getters.getBlock

        }
    },
    methods: {
        validate() {
            this.$refs.form.validate()
            let $vm = this
            setTimeout(function () {
                if ($vm.valid == true) {
                    console.log(this.valid)
                    $vm.login()
                }
            }, 100);
        },

        setUser(data) {

            console.log(data)

            let $vm = this
            $vm.$SALE_ID = data.sale_id
            console.log(data)

            $vm.setCookie(this.$C_TOKEN, data.token)

            if (data.sale) {
                $vm.$STORE_SALE.commit("set", data.sale);
                $vm.$STORE_SALE.commit("setId", data.sale.sale_id);

            }

            console.log($vm.getCookie(this.$C_TOKEN))

        },

        login() {
            let $vm = this;

            $vm.loginMsg = "";

            this.setCookie(this.$C_TOKEN, null)
            const formData = new FormData();
            formData.append("username", $vm.username);
            formData.append("password", $vm.password);

            var url = "user/login?time=" + Math.random();
            // console.log(url);
            this.$http
                .post(url, formData)
                .then(function (res) {
                    console.log(res.data );
                    $vm.logingIn = false
                    $vm.loginMsg = res.data.loginMsg;

                    if (res.data.user_check == false) {
                        $vm.boxLoginClass = "animate__animated animate__shakeX";
                        setTimeout(function () {
                            $vm.boxLoginClass = "";
                        }, 1000);

                    } else {

                        //console.log(res.data)

                        $vm.setCookie($vm.$C_LOGIN, res.data.LOGIN_ID)

                        $vm.setUser(res.data)

                        $vm.checkUser()

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        checkUser() {
            let $vm = this;

            if (this.token()) {
                var url = "user/get?time=" + Math.random();
                  console.log(url);
                this.$http
                    .post(url, this.formData())
                    .then(function (res) {
                        console.log(res.data);
                        if (res.data.user) {
                            $vm.setUser(res.data)
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },

    },
    created: function () {

        if (!this.$SALE_ID) {
            this.checkUser()
            //   console.log('Login')
        }

    },

}
</script>
