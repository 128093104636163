import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify';
import "vuetify/dist/vuetify.min.css";

import moment from "moment";

require("moment/locale/th");

// Choose Locale
moment.locale("th");

Vue.prototype.$moment = moment;



Vue.config.productionTip = false

import "./assets/css/custom.css";

Vue.prototype.$C_VISIT = process.env.VUE_APP_VISIT;
Vue.prototype.$C_TOKEN = process.env.VUE_APP_TOKEN;
Vue.prototype.$C_LOGIN = process.env.VUE_APP_LOGIN;
Vue.prototype.$C_COOKIE_DOMAIN = process.env.VUE_APP_COOKIE_DOMAIN;
Vue.prototype.$C_MAIN_SITE = process.env.VUE_APP_MAIN_SITE;

 

console.log(Vue.prototype.$C_MAIN_SITE);


console.log(Vue.prototype.$C_COOKIE_DOMAIN);

import axios from "axios";
import "./Core/function";
import "./Core/member_function.js";
import "./custom_function.js";

console.log(process.env.VUE_APP_API_URL);

const API = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60 * 4 * 1000,
});

Vue.prototype.$API = API;
Vue.prototype.$http = API;

  

new Vue({
  router,
  store,
  vuetify,
  components: { App },
  render: (h) => h(App),
}).$mount("#app");
