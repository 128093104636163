import Vue from "vue";
import Vuex from "vuex";



Vue.use(Vuex);

const storeGlobal = new Vuex.Store({
  strict: true,

  state: {
    BLOCK: "no",
    pointStatus: {
      point_rule_id: null,
      data_id: null,
       data_type:null,
    }
  },
  mutations: {
    setBlock(state, data) {
      state.BLOCK = data;
    },
    setPointStatus(state, data) {
      state.pointStatus = data;
    }
  },
  actions: {},
  getters: {
    getBlock: state => {
      return state.BLOCK;
    },

    getPointStatus: state => {
      return state.pointStatus;
    }
  }
});

export default storeGlobal;
