import Vue from "vue";
var VueCookie = require("vue-cookie");
// Tell Vue to use the plugin
Vue.use(VueCookie);

Vue.mixin({
  methods: {
   
  },
});
