<template>
<div>
    <v-app-bar color="blue darken-3" dense dark class="pl-3 pr-3">
        <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon> 
        <v-toolbar-title>BKK Realty</v-toolbar-title> 
        <v-spacer></v-spacer>

        <v-menu left bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </template> 
            <v-list> 
                <v-list-item :to="{name:'accountProfile'}" >
                    <v-list-item-title>โปรไฟล์</v-list-item-title>
                </v-list-item>

                     <v-list-item :to="{name:'accountChangePassword'}" >
                    <v-list-item-title>เปลี่ยนรหัสผ่าน</v-list-item-title>
                </v-list-item>

                <v-list-item @click="goLogout()">
                    <v-list-item-title>ออกจากระบบ</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

    </v-app-bar>
    <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
            <v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
                <v-list-item :to="{name:'propertyUsed'}">
              
                    <v-list-item-title>รายการมือสอง</v-list-item-title>
                </v-list-item>

                        <v-list-item :to="{name:'Contact'}">
                 
                    <v-list-item-title>ติดต่อสอบถาม</v-list-item-title>
                </v-list-item>


                   <v-list-item :to="{name:'propertyNew'}">
                
                    <v-list-item-title>รายการโครงการใหม่</v-list-item-title>
                </v-list-item>


                 


                    <v-list-item :to="{name:'Register'}">
                 
                    <v-list-item-title>ลงทะเบียนโครงการใหม่</v-list-item-title>
                </v-list-item>



              
            </v-list-item-group>
        </v-list>
    </v-navigation-drawer>
</div>
</template>

<script>
export default {

    components: {

    },

    data: () => ({
        drawer: false,
        group: null,
    }),

    computed: {

    },

    watch: {

    },
    methods: {
   goLogout() {

            let $vm = this
            $vm.$SALE_ID = null
            this.setCookie(this.$C_TOKEN, null)
            this.setCookie(this.$C_LOGIN, null)

            $vm.$STORE_USER.commit("setLogout", null);
            $vm.$STORE_USER.commit("set", null);
            $vm.$STORE_USER.commit("setId", null);
             

        }
    },
    created() {

    },
};
</script>
