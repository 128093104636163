import Vue from "vue";

import storeGlobal from "../store/global.js";
import storeUser from "../store/user.js";
Vue.prototype.$STORE_GLOBAL = storeGlobal;
Vue.prototype.$STORE_USER = storeUser;


let globalData = new Vue({
  data: {
    $SALE_ID: null,
    $LOADING: null, 
  },
});
Vue.mixin({
  computed: {
    $SALE_ID: {
      get: function() {
        return globalData.$data.$SALE_ID;
      },
      set: function(newValue) {
        globalData.$data.$SALE_ID = newValue;
      },
    },

    $LOADING: {
      get: function() {
        return globalData.$data.$LOADING;
      },
      set: function(newValue) {
        globalData.$data.$LOADING = newValue;
      },
    },
  },

  methods: {
    setCookie(key, value) {
      localStorage.setItem(key, value);
    },
    getCookie(key) {
      return localStorage.getItem(key);
    },

    ie11() {
      var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      return isIE11;
    },

    formData(formData) {
      let $vm = this;
      let formD = null;
      if (!formData) {
        formD = new FormData();
      } else {
        formD = formData;
      }
      formD.append("Token", $vm.getCookie($vm.$C_TOKEN));

      formD.append("VISIT_ID", $vm.getCookie($vm.$C_VISIT));

      formD.append("LOGIN_ID", $vm.getCookie($vm.$C_LOGIN));

      return formD;
    },
    token() {
      let token = this.getCookie(this.$C_TOKEN);
      console.log(token);

      if (token == "null") {
        token = null;
      }

      if (token) {
        console.log("yes");
        return token;
      } else {
        console.log("no");
        return false;
      }
    },

    itemSplit(items, page, perPage) {
      let it = [];
      if (items) {
        let from = page * perPage - perPage;
        let to = page * perPage;

        it = items.slice(from, to);
      }

      return it;
    },
    goTo(goto) {
      var route = this.$router.currentRoute.fullPath;
      Vue.prototype.$SAME_PAGE = true;
      if (goto != route) {
        this.$router.replace(goto);
      }
    },
  },
});
